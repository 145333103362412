import axios from "axios";
import {
  productsRequest,
  productsFail,
  productsSuccess,
  adminProductsRequest,
  adminProductsSuccess,
  adminProductsFail,
} from "../slices/productsSlice";

import {
  productFail,
  productSuccess,
  productRequest,
  newProductRequest,
  newProductSuccess,
  newProductFail,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFail,
  updateProductRequest,
  updateProductSuccess,
  updateProductFail,
} from "../slices/productSlice";

import {
  relatedProductsRequest,
  relatedProductsSuccess,
  relatedProductsFail,
} from "../slices/relatedProductsSlice";

export const getProducts = async (dispatch) => {
  try {
    dispatch(productsRequest());
    const { data } = await axios.get(
      "https://igold.teammatrixmantra.com/jewellery/products"
    );
    dispatch(productsSuccess(data));
  } catch (error) {
    dispatch(productsFail(error.response.data.message));
  }
};

export const getProduct = (id) => async (dispatch) => {
  try {
    dispatch(productRequest());
    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/jewellery/product/${id}`
    );
    console.log("Product Data from API:", data); // Add this line to log the data
    dispatch(productSuccess(data));
    return { product: data };
  } catch (error) {
    //handle error
    dispatch(productFail(error.response.data.message));
  }
};

export const getRelatedProducts =
  (category, excludeProductId) => async (dispatch) => {
    try {
      dispatch(relatedProductsRequest());
      const { data } = await axios.get(
        `https://igold.teammatrixmantra.com/jewellery/products?category=${category}&exclude=${excludeProductId}`
      );
      dispatch(relatedProductsSuccess(data)); // Ensure you are using the correct action here
      return { success: true, data }; // Explicitly returning the data
    } catch (error) {
      // Handle error
      dispatch(
        relatedProductsFail(
          error.response?.data?.message || "Failed to fetch related products"
        )
      );
      return { success: false, error: error.message };
    }
  };

export const getAdminProducts = async (dispatch) => {
  try {
    dispatch(adminProductsRequest());
    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/jewellery/admin/products`
    );
    dispatch(adminProductsSuccess(data));
  } catch (error) {
    //handle error
    dispatch(adminProductsFail(error.response.data.message));
  }
};

export const createNewProduct = (productData) => async (dispatch) => {
  try {
    dispatch(newProductRequest());
    const { data } = await axios.post(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/new`,
      productData
    );
    dispatch(newProductSuccess(data));
  } catch (error) {
    //handle error
    dispatch(newProductFail(error.response.data.message));
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch(deleteProductRequest());
    await axios.delete(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/${id}`
    );
    dispatch(deleteProductSuccess());
  } catch (error) {
    //handle error
    dispatch(deleteProductFail(error.response.data.message));
  }
};

export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch(updateProductRequest());
    const { data } = await axios.put(
      `https://igold.teammatrixmantra.com/jewellery/admin/product/${id}`,
      productData
    );
    dispatch(updateProductSuccess(data));
  } catch (error) {
    //handle error
    dispatch(updateProductFail(error.response.data.message));
  }
};
