import {
  loginFail,
  loginRequest,
  loginSuccess,
  clearError,
  registerFail,
  registerRequest,
  registerSuccess,
  loadUserRequest,
  loadUserSuccess,
  loadUserFail,
  logoutSuccess,
  logoutFail,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFail,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFail,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFail,
} from "../slices/authSlice";

// import {
//     usersRequest,
//     usersSuccess,
//     usersFail,
//     userRequest,
//     userSuccess,
//     userFail,
//     deleteUserRequest,
//     deleteUserSuccess,
//     deleteUserFail,
//     updateUserRequest,
//     updateUserSuccess,
//     updateUserFail

// } from '../slices/userSlice'

import axios from "axios";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const { data } = await axios.post(
      `https://igold.teammatrixmantra.com/jewellery/login`,
      { email, password }
    );
    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFail(error.response.data.message));
  }
};

// export const clearAuthError = dispatch => {
//     dispatch(clearError())
// }

export const clearAuthError = () => (dispatch) => {
  dispatch(clearError());
};

export const register = (userData) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      `https://igold.teammatrixmantra.com/jewellery/register`,
      userData,
      config
    );
    dispatch(registerSuccess(data));
  } catch (error) {
    dispatch(registerFail(error.response.data.message));
  }
};

export const loadUser = async (dispatch) => {
  try {
    dispatch(loadUserRequest());

    const { data } = await axios.get(
      `https://igold.teammatrixmantra.com/jewellery/myprofile`
    );
    dispatch(loadUserSuccess(data));
  } catch (error) {
    dispatch(loadUserFail(error.response.data.message));
  }
};

export const logout = async (dispatch) => {
  try {
    await axios.get(`https://igold.teammatrixmantra.com/jewellery/logout`);
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFail);
  }
};

export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch(updateProfileRequest());
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.put(
      `https://igold.teammatrixmantra.com/jewellery/update`,
      userData,
      config
    );
    dispatch(updateProfileSuccess(data));
  } catch (error) {
    dispatch(updateProfileFail(error.response.data.message));
  }
};

export const updatePassword = (formData) => async (dispatch) => {
  try {
    dispatch(updatePasswordRequest());
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    await axios.put(
      `https://igold.teammatrixmantra.com/jewellery/password/change`,
      formData,
      config
    );
    dispatch(updatePasswordSuccess());
  } catch (error) {
    dispatch(updatePasswordFail(error.response.data.message));
  }
};

// export const forgotPassword = (formData) => async (dispatch) => {

//     try {
//         dispatch(forgotPasswordRequest())
//         const config = {
//             headers: {
//                 'Content-type': 'application/json'
//             }
//         }
//         const { data} =  await axios.post(`/jewellery/password/forgot`, formData, config);
//         dispatch(forgotPasswordSuccess(data))
//     } catch (error) {
//         dispatch(forgotPasswordFail(error.response.data.message))
//     }

// }

// export const resetPassword = (formData, token) => async (dispatch) => {

//     try {
//         dispatch(resetPasswordRequest())
//         const config = {
//             headers: {
//                 'Content-type': 'application/json'
//             }
//         }
//         const { data} =  await axios.post(`/jewellery/password/reset/${token}`, formData, config);
//         dispatch(resetPasswordSuccess(data))
//     } catch (error) {
//         dispatch(resetPasswordFail(error.response.data.message))
//     }

// }

// export const getUsers =  async (dispatch) => {

//     try {
//         dispatch(usersRequest())
//         const { data }  = await axios.get(`/jewellery/admin/users`);
//         dispatch(usersSuccess(data))
//     } catch (error) {
//         dispatch(usersFail(error.response.data.message))
//     }

// }

// export const getUser = id => async (dispatch) => {

//     try {
//         dispatch(userRequest())
//         const { data }  = await axios.get(`/jewellery/admin/user/${id}`);
//         dispatch(userSuccess(data))
//     } catch (error) {
//         dispatch(userFail(error.response.data.message))
//     }

// }

// export const deleteUser = id => async (dispatch) => {

//     try {
//         dispatch(deleteUserRequest())
//         await axios.delete(`/jewellery/admin/user/${id}`);
//         dispatch(deleteUserSuccess())
//     } catch (error) {
//         dispatch(deleteUserFail(error.response.data.message))
//     }

// }

// export const updateUser = (id, formData) => async (dispatch) => {

//     try {
//         dispatch(updateUserRequest())
//         const config = {
//             headers: {
//                 'Content-type': 'application/json'
//             }
//         }
//         await axios.put(`/jewellery/admin/user/${id}`, formData, config);
//         dispatch(updateUserSuccess())
//     } catch (error) {
//         dispatch(updateUserFail(error.response.data.message))
//     }

// }
